import { memo } from 'react';
import { captureMessage } from '@sentry/nextjs';
import { createContentfulClient } from 'apollo/clients';
import {
  GetPromotionBannersDocument,
  GetPromotionBannersQuery,
} from 'generated/contentful-graphql';
import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { contentfulLocale } from 'utils/contentfulLocale';
import { SWR_CACHE_CONTROL } from 'utils/swrCacheControl';
import Home from 'views/Home';
import { BannerItem, BannerSectionVariant } from 'views/Home/HomeBannerSection';

export const HomePage = ({ banners }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <Head>
        <title>Shuffle.com | Crypto Casino & VIP Betting Platform</title>
      </Head>
      <Home banners={banners} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  context.res.setHeader('Cache-Control', SWR_CACHE_CONTROL.STANDARD);

  const localeProps = context.locale
    ? await serverSideTranslations(context.locale, ['common', 'errors'])
    : undefined;

  const isPreview = context.query.preview === 'true';
  const homePageProps = await getHomePageComponentProps(isPreview, context.locale);

  return {
    props: {
      casinoLayout: true,
      activityBoard: true,
      ...homePageProps,
      ...localeProps,
    },
  };
};

type GetHomePageComponentPropsReturn = {
  banners: BannerItem[] | null;
};

/**
 * Fetches the home page props to be used from other
 * pages to pass into the <Home/> component.
 */
export const getHomePageComponentProps = async (
  preview: boolean,
  locale?: string,
): Promise<GetHomePageComponentPropsReturn> => {
  // Create a new client on each request to avoid cache issues.
  const contentfulClient = createContentfulClient({ preview });

  try {
    // Fetch page data from Contentful
    const response = await contentfulClient.query<GetPromotionBannersQuery>({
      query: GetPromotionBannersDocument,
      variables: {
        preview,
        now: new Date().toISOString(),
        locale: contentfulLocale(locale),
      },
    });

    // Add promotion to page props; if it doesn't exist; show the user client-side.
    const promotionItems = response.data.promotionCollection?.items ?? [];
    const banners: BannerItem[] = promotionItems.map(item => ({
      type: (item?.type ?? 'casino') as BannerSectionVariant,
      href: `/promotions/${item?.slug}`,
      image: item?.image?.url ?? '',
      alt: item?.image?.description ?? '',
    }));

    return {
      banners,
    };
  } catch (e) {
    // Report to Sentry
    captureMessage(`Promotion banners request failed ${(e as Error)?.message || ''}`, {
      extra: {
        file: 'src/pages/index.tsx',
      },
      level: 'error',
    });

    return { banners: null };
  }
};

export default memo(HomePage);

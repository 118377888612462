import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Dimension: any;
  HexColor: any;
  JSON: any;
  Quality: any;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
  transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  promotionCollection?: Maybe<PromotionCollection>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPromotionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsPromotionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AssetLinkingCollectionsPromotionCollectionOrder {
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDSAT_ASC = 'endsAt_ASC',
  ENDSAT_DESC = 'endsAt_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRECEDENCE_ASC = 'precedence_ASC',
  PRECEDENCE_DESC = 'precedence_DESC',
  SLUG_ASC = 'slug_ASC',
  SLUG_DESC = 'slug_DESC',
  STARTSAT_ASC = 'startsAt_ASC',
  STARTSAT_DESC = 'startsAt_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
}

export enum AssetOrder {
  CONTENTTYPE_ASC = 'contentType_ASC',
  CONTENTTYPE_DESC = 'contentType_DESC',
  FILENAME_ASC = 'fileName_ASC',
  FILENAME_DESC = 'fileName_DESC',
  HEIGHT_ASC = 'height_ASC',
  HEIGHT_DESC = 'height_DESC',
  SIZE_ASC = 'size_ASC',
  SIZE_DESC = 'size_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  URL_ASC = 'url_ASC',
  URL_DESC = 'url_DESC',
  WIDTH_ASC = 'width_ASC',
  WIDTH_DESC = 'width_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/author) */
export type Author = Entry & {
  __typename?: 'Author';
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<AuthorLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
};

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/author) */
export type AuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/author) */
export type AuthorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AuthorCollection = {
  __typename?: 'AuthorCollection';
  items: Array<Maybe<Author>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AuthorLinkingCollections = {
  __typename?: 'AuthorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  promotionCollection?: Maybe<PromotionCollection>;
};

export type AuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AuthorLinkingCollectionsPromotionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AuthorLinkingCollectionsPromotionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AuthorLinkingCollectionsPromotionCollectionOrder {
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDSAT_ASC = 'endsAt_ASC',
  ENDSAT_DESC = 'endsAt_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRECEDENCE_ASC = 'precedence_ASC',
  PRECEDENCE_DESC = 'precedence_DESC',
  SLUG_ASC = 'slug_ASC',
  SLUG_DESC = 'slug_DESC',
  STARTSAT_ASC = 'startsAt_ASC',
  STARTSAT_DESC = 'startsAt_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
}

export enum AuthorOrder {
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/button) */
export type Button = Entry & {
  __typename?: 'Button';
  contentfulMetadata: ContentfulMetadata;
  href?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ButtonLinkingCollections>;
  sys: Sys;
  value?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/button) */
export type ButtonHrefArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/button) */
export type ButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/button) */
export type ButtonValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ButtonCollection = {
  __typename?: 'ButtonCollection';
  items: Array<Maybe<Button>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  href?: InputMaybe<Scalars['String']>;
  href_contains?: InputMaybe<Scalars['String']>;
  href_exists?: InputMaybe<Scalars['Boolean']>;
  href_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  href_not?: InputMaybe<Scalars['String']>;
  href_not_contains?: InputMaybe<Scalars['String']>;
  href_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ButtonLinkingCollections = {
  __typename?: 'ButtonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  promotionCollection?: Maybe<PromotionCollection>;
};

export type ButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ButtonLinkingCollectionsPromotionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonLinkingCollectionsPromotionCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ButtonLinkingCollectionsPromotionCollectionOrder {
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDSAT_ASC = 'endsAt_ASC',
  ENDSAT_DESC = 'endsAt_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRECEDENCE_ASC = 'precedence_ASC',
  PRECEDENCE_DESC = 'precedence_DESC',
  SLUG_ASC = 'slug_ASC',
  SLUG_DESC = 'slug_DESC',
  STARTSAT_ASC = 'startsAt_ASC',
  STARTSAT_DESC = 'startsAt_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
}

export enum ButtonOrder {
  HREF_ASC = 'href_ASC',
  HREF_DESC = 'href_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  VALUE_ASC = 'value_ASC',
  VALUE_DESC = 'value_DESC',
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
}

export enum ImageFormat {
  AVIF = 'AVIF',
  /** JPG image format. */
  JPG = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JPG_PROGRESSIVE = 'JPG_PROGRESSIVE',
  /** PNG image format */
  PNG = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  PNG8 = 'PNG8',
  /** WebP image format. */
  WEBP = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  BOTTOM = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  CENTER = 'CENTER',
  /** Focus the resizing on the largest face. */
  FACE = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  FACES = 'FACES',
  /** Focus the resizing on the left. */
  LEFT = 'LEFT',
  /** Focus the resizing on the right. */
  RIGHT = 'RIGHT',
  /** Focus the resizing on the top. */
  TOP = 'TOP',
  /** Focus the resizing on the top left. */
  TOP_LEFT = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TOP_RIGHT = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  CROP = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  FILL = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  FIT = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  PAD = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  SCALE = 'SCALE',
  /** Creates a thumbnail from the image. */
  THUMB = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type Policy = Entry & {
  __typename?: 'Policy';
  body?: Maybe<PolicyBody>;
  body1?: Maybe<PolicyBody1>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PolicyLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sys: Sys;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicyBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicyBody1Args = {
  locale?: InputMaybe<Scalars['String']>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicyNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicyOrderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** policy docs [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/policy) */
export type PolicySlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PolicyBody = {
  __typename?: 'PolicyBody';
  json: Scalars['JSON'];
  links: PolicyBodyLinks;
};

export type PolicyBody1 = {
  __typename?: 'PolicyBody1';
  json: Scalars['JSON'];
  links: PolicyBody1Links;
};

export type PolicyBody1Assets = {
  __typename?: 'PolicyBody1Assets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PolicyBody1Entries = {
  __typename?: 'PolicyBody1Entries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PolicyBody1Links = {
  __typename?: 'PolicyBody1Links';
  assets: PolicyBody1Assets;
  entries: PolicyBody1Entries;
  resources: PolicyBody1Resources;
};

export type PolicyBody1Resources = {
  __typename?: 'PolicyBody1Resources';
  block: Array<ResourceLink>;
};

export type PolicyBodyAssets = {
  __typename?: 'PolicyBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PolicyBodyEntries = {
  __typename?: 'PolicyBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PolicyBodyLinks = {
  __typename?: 'PolicyBodyLinks';
  assets: PolicyBodyAssets;
  entries: PolicyBodyEntries;
  resources: PolicyBodyResources;
};

export type PolicyBodyResources = {
  __typename?: 'PolicyBodyResources';
  block: Array<ResourceLink>;
};

export type PolicyCollection = {
  __typename?: 'PolicyCollection';
  items: Array<Maybe<Policy>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PolicyFilter = {
  AND?: InputMaybe<Array<InputMaybe<PolicyFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PolicyFilter>>>;
  body1_contains?: InputMaybe<Scalars['String']>;
  body1_exists?: InputMaybe<Scalars['Boolean']>;
  body1_not_contains?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<Scalars['Int']>;
  order_exists?: InputMaybe<Scalars['Boolean']>;
  order_gt?: InputMaybe<Scalars['Int']>;
  order_gte?: InputMaybe<Scalars['Int']>;
  order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order_lt?: InputMaybe<Scalars['Int']>;
  order_lte?: InputMaybe<Scalars['Int']>;
  order_not?: InputMaybe<Scalars['Int']>;
  order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PolicyLinkingCollections = {
  __typename?: 'PolicyLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PolicyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PolicyOrder {
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  ORDER_ASC = 'order_ASC',
  ORDER_DESC = 'order_DESC',
  SLUG_ASC = 'slug_ASC',
  SLUG_DESC = 'slug_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
}

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type Promotion = Entry & {
  __typename?: 'Promotion';
  author?: Maybe<Author>;
  button?: Maybe<Button>;
  content?: Maybe<PromotionContent>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<PromotionLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  precedence?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  sys: Sys;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  termsAndConditions?: Maybe<PromotionTermsAndConditions>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AuthorFilter>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ButtonFilter>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionEndsAtArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionPrecedenceArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionStartsAtArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionTagsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionTermsAndConditionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** A Shuffle promotion as displayed on the home page. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/promotion) */
export type PromotionTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PromotionCollection = {
  __typename?: 'PromotionCollection';
  items: Array<Maybe<Promotion>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PromotionContent = {
  __typename?: 'PromotionContent';
  json: Scalars['JSON'];
  links: PromotionContentLinks;
};

export type PromotionContentAssets = {
  __typename?: 'PromotionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromotionContentEntries = {
  __typename?: 'PromotionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromotionContentLinks = {
  __typename?: 'PromotionContentLinks';
  assets: PromotionContentAssets;
  entries: PromotionContentEntries;
  resources: PromotionContentResources;
};

export type PromotionContentResources = {
  __typename?: 'PromotionContentResources';
  block: Array<ResourceLink>;
};

export type PromotionFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromotionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromotionFilter>>>;
  author?: InputMaybe<CfAuthorNestedFilter>;
  author_exists?: InputMaybe<Scalars['Boolean']>;
  button?: InputMaybe<CfButtonNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  endsAt_exists?: InputMaybe<Scalars['Boolean']>;
  endsAt_gt?: InputMaybe<Scalars['DateTime']>;
  endsAt_gte?: InputMaybe<Scalars['DateTime']>;
  endsAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  endsAt_lt?: InputMaybe<Scalars['DateTime']>;
  endsAt_lte?: InputMaybe<Scalars['DateTime']>;
  endsAt_not?: InputMaybe<Scalars['DateTime']>;
  endsAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  precedence?: InputMaybe<Scalars['Int']>;
  precedence_exists?: InputMaybe<Scalars['Boolean']>;
  precedence_gt?: InputMaybe<Scalars['Int']>;
  precedence_gte?: InputMaybe<Scalars['Int']>;
  precedence_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  precedence_lt?: InputMaybe<Scalars['Int']>;
  precedence_lte?: InputMaybe<Scalars['Int']>;
  precedence_not?: InputMaybe<Scalars['Int']>;
  precedence_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  startsAt_exists?: InputMaybe<Scalars['Boolean']>;
  startsAt_gt?: InputMaybe<Scalars['DateTime']>;
  startsAt_gte?: InputMaybe<Scalars['DateTime']>;
  startsAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsAt_lt?: InputMaybe<Scalars['DateTime']>;
  startsAt_lte?: InputMaybe<Scalars['DateTime']>;
  startsAt_not?: InputMaybe<Scalars['DateTime']>;
  startsAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  sys?: InputMaybe<SysFilter>;
  tags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions_contains?: InputMaybe<Scalars['String']>;
  termsAndConditions_exists?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions_not_contains?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PromotionLinkingCollections = {
  __typename?: 'PromotionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PromotionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PromotionOrder {
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  ENDSAT_ASC = 'endsAt_ASC',
  ENDSAT_DESC = 'endsAt_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRECEDENCE_ASC = 'precedence_ASC',
  PRECEDENCE_DESC = 'precedence_DESC',
  SLUG_ASC = 'slug_ASC',
  SLUG_DESC = 'slug_DESC',
  STARTSAT_ASC = 'startsAt_ASC',
  STARTSAT_DESC = 'startsAt_DESC',
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  TYPE_ASC = 'type_ASC',
  TYPE_DESC = 'type_DESC',
}

export type PromotionTermsAndConditions = {
  __typename?: 'PromotionTermsAndConditions';
  json: Scalars['JSON'];
  links: PromotionTermsAndConditionsLinks;
};

export type PromotionTermsAndConditionsAssets = {
  __typename?: 'PromotionTermsAndConditionsAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromotionTermsAndConditionsEntries = {
  __typename?: 'PromotionTermsAndConditionsEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromotionTermsAndConditionsLinks = {
  __typename?: 'PromotionTermsAndConditionsLinks';
  assets: PromotionTermsAndConditionsAssets;
  entries: PromotionTermsAndConditionsEntries;
  resources: PromotionTermsAndConditionsResources;
};

export type PromotionTermsAndConditionsResources = {
  __typename?: 'PromotionTermsAndConditionsResources';
  block: Array<ResourceLink>;
};

export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  author?: Maybe<Author>;
  authorCollection?: Maybe<AuthorCollection>;
  button?: Maybe<Button>;
  buttonCollection?: Maybe<ButtonCollection>;
  entryCollection?: Maybe<EntryCollection>;
  policy?: Maybe<Policy>;
  policyCollection?: Maybe<PolicyCollection>;
  promotion?: Maybe<Promotion>;
  promotionCollection?: Maybe<PromotionCollection>;
  sportsNavigation?: Maybe<SportsNavigation>;
  sportsNavigationCollection?: Maybe<SportsNavigationCollection>;
};

export type QueryAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilter>;
};

export type QueryAuthorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AuthorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorFilter>;
};

export type QueryButtonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ButtonFilter>;
};

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntryFilter>;
};

export type QueryPolicyArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPolicyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PolicyOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PolicyFilter>;
};

export type QueryPromotionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPromotionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PromotionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromotionFilter>;
};

export type QuerySportsNavigationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySportsNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SportsNavigationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SportsNavigationFilter>;
};

export type ResourceLink = {
  __typename?: 'ResourceLink';
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String'];
  type: Scalars['String'];
  urn: Scalars['String'];
};

/** Sports page left side navigation. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/sportsNavigation) */
export type SportsNavigation = Entry & {
  __typename?: 'SportsNavigation';
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<SportsNavigationLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};

/** Sports page left side navigation. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/sportsNavigation) */
export type SportsNavigationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sports page left side navigation. [See type definition](https://app.contentful.com/spaces/9mngrv1pdae6/content_types/sportsNavigation) */
export type SportsNavigationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SportsNavigationCollection = {
  __typename?: 'SportsNavigationCollection';
  items: Array<Maybe<SportsNavigation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SportsNavigationFilter = {
  AND?: InputMaybe<Array<InputMaybe<SportsNavigationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SportsNavigationFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SportsNavigationLinkingCollections = {
  __typename?: 'SportsNavigationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SportsNavigationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SportsNavigationOrder {
  SYS_FIRSTPUBLISHEDAT_ASC = 'sys_firstPublishedAt_ASC',
  SYS_FIRSTPUBLISHEDAT_DESC = 'sys_firstPublishedAt_DESC',
  SYS_ID_ASC = 'sys_id_ASC',
  SYS_ID_DESC = 'sys_id_DESC',
  SYS_PUBLISHEDAT_ASC = 'sys_publishedAt_ASC',
  SYS_PUBLISHEDAT_DESC = 'sys_publishedAt_DESC',
  SYS_PUBLISHEDVERSION_ASC = 'sys_publishedVersion_ASC',
  SYS_PUBLISHEDVERSION_DESC = 'sys_publishedVersion_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
}

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type CfAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfButtonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfButtonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  href?: InputMaybe<Scalars['String']>;
  href_contains?: InputMaybe<Scalars['String']>;
  href_exists?: InputMaybe<Scalars['Boolean']>;
  href_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  href_not?: InputMaybe<Scalars['String']>;
  href_not_contains?: InputMaybe<Scalars['String']>;
  href_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetPoliciesQueryVariables = Exact<{
  preview: Scalars['Boolean'];
  locale: Scalars['String'];
}>;

export type GetPoliciesQuery = {
  __typename?: 'Query';
  policyCollection?: {
    __typename?: 'PolicyCollection';
    items: Array<{
      __typename?: 'Policy';
      name?: string | null;
      slug?: string | null;
      body?: { __typename?: 'PolicyBody'; json: any } | null;
      body1?: { __typename?: 'PolicyBody1'; json: any } | null;
    } | null>;
  } | null;
};

export type GetPromotionBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  locale: Scalars['String'];
}>;

export type GetPromotionBySlugQuery = {
  __typename?: 'Query';
  promotionCollection?: {
    __typename?: 'PromotionCollection';
    items: Array<{
      __typename?: 'Promotion';
      title?: string | null;
      description?: string | null;
      type?: string | null;
      startsAt?: any | null;
      endsAt?: any | null;
      tags?: Array<string | null> | null;
      slug?: string | null;
      precedence?: number | null;
      author?: { __typename?: 'Author'; name?: string | null } | null;
      button?: { __typename?: 'Button'; href?: string | null; value?: string | null } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        description?: string | null;
      } | null;
      content?: { __typename?: 'PromotionContent'; json: any } | null;
      termsAndConditions?: { __typename?: 'PromotionTermsAndConditions'; json: any } | null;
    } | null>;
  } | null;
};

export type GetPromotionByIdQueryVariables = Exact<{
  id: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPromotionByIdQuery = {
  __typename?: 'Query';
  promotionCollection?: {
    __typename?: 'PromotionCollection';
    items: Array<{
      __typename?: 'Promotion';
      title?: string | null;
      description?: string | null;
      type?: string | null;
      startsAt?: any | null;
      endsAt?: any | null;
      tags?: Array<string | null> | null;
      slug?: string | null;
      precedence?: number | null;
      author?: { __typename?: 'Author'; name?: string | null } | null;
      button?: { __typename?: 'Button'; href?: string | null; value?: string | null } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        description?: string | null;
      } | null;
      content?: { __typename?: 'PromotionContent'; json: any } | null;
      termsAndConditions?: { __typename?: 'PromotionTermsAndConditions'; json: any } | null;
    } | null>;
  } | null;
};

export type PromotionFieldsFragment = {
  __typename?: 'Promotion';
  title?: string | null;
  description?: string | null;
  type?: string | null;
  startsAt?: any | null;
  endsAt?: any | null;
  tags?: Array<string | null> | null;
  slug?: string | null;
  precedence?: number | null;
  author?: { __typename?: 'Author'; name?: string | null } | null;
  button?: { __typename?: 'Button'; href?: string | null; value?: string | null } | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    description?: string | null;
  } | null;
  content?: { __typename?: 'PromotionContent'; json: any } | null;
  termsAndConditions?: { __typename?: 'PromotionTermsAndConditions'; json: any } | null;
};

export type GetPromotionBannersQueryVariables = Exact<{
  now: Scalars['DateTime'];
  preview: Scalars['Boolean'];
  locale: Scalars['String'];
}>;

export type GetPromotionBannersQuery = {
  __typename?: 'Query';
  promotionCollection?: {
    __typename?: 'PromotionCollection';
    items: Array<{
      __typename?: 'Promotion';
      type?: string | null;
      title?: string | null;
      description?: string | null;
      startsAt?: any | null;
      endsAt?: any | null;
      slug?: string | null;
      precedence?: number | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        description?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const PromotionFieldsFragmentDoc = gql`
  fragment PromotionFields on Promotion {
    title
    description
    author {
      name
    }
    button {
      href
      value
    }
    image {
      url
      title
      width
      height
      description
    }
    content {
      json
    }
    termsAndConditions {
      json
    }
    type
    startsAt
    endsAt
    tags
    slug
    precedence
  }
`;
export const GetPoliciesDocument = gql`
  query GetPolicies($preview: Boolean!, $locale: String!) {
    policyCollection(preview: $preview, order: [order_ASC], locale: $locale) {
      items {
        name
        slug
        body {
          json
        }
        body1 {
          json
        }
      }
    }
  }
`;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *      preview: // value for 'preview'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
}
export function useGetPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(
    GetPoliciesDocument,
    options,
  );
}
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<
  GetPoliciesQuery,
  GetPoliciesQueryVariables
>;
export const GetPromotionBySlugDocument = gql`
  query GetPromotionBySlug($slug: String!, $preview: Boolean, $locale: String!) {
    promotionCollection(preview: $preview, where: { slug: $slug }, limit: 1, locale: $locale) {
      items {
        ...PromotionFields
      }
    }
  }
  ${PromotionFieldsFragmentDoc}
`;

/**
 * __useGetPromotionBySlugQuery__
 *
 * To run a query within a React component, call `useGetPromotionBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      preview: // value for 'preview'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPromotionBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<GetPromotionBySlugQuery, GetPromotionBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromotionBySlugQuery, GetPromotionBySlugQueryVariables>(
    GetPromotionBySlugDocument,
    options,
  );
}
export function useGetPromotionBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPromotionBySlugQuery,
    GetPromotionBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromotionBySlugQuery, GetPromotionBySlugQueryVariables>(
    GetPromotionBySlugDocument,
    options,
  );
}
export type GetPromotionBySlugQueryHookResult = ReturnType<typeof useGetPromotionBySlugQuery>;
export type GetPromotionBySlugLazyQueryHookResult = ReturnType<
  typeof useGetPromotionBySlugLazyQuery
>;
export type GetPromotionBySlugQueryResult = Apollo.QueryResult<
  GetPromotionBySlugQuery,
  GetPromotionBySlugQueryVariables
>;
export const GetPromotionByIdDocument = gql`
  query GetPromotionById($id: String!, $preview: Boolean) {
    promotionCollection(preview: $preview, where: { sys: { id: $id } }, limit: 1) {
      items {
        ...PromotionFields
      }
    }
  }
  ${PromotionFieldsFragmentDoc}
`;

/**
 * __useGetPromotionByIdQuery__
 *
 * To run a query within a React component, call `useGetPromotionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGetPromotionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPromotionByIdQuery, GetPromotionByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromotionByIdQuery, GetPromotionByIdQueryVariables>(
    GetPromotionByIdDocument,
    options,
  );
}
export function useGetPromotionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionByIdQuery, GetPromotionByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromotionByIdQuery, GetPromotionByIdQueryVariables>(
    GetPromotionByIdDocument,
    options,
  );
}
export type GetPromotionByIdQueryHookResult = ReturnType<typeof useGetPromotionByIdQuery>;
export type GetPromotionByIdLazyQueryHookResult = ReturnType<typeof useGetPromotionByIdLazyQuery>;
export type GetPromotionByIdQueryResult = Apollo.QueryResult<
  GetPromotionByIdQuery,
  GetPromotionByIdQueryVariables
>;
export const GetPromotionBannersDocument = gql`
  query GetPromotionBanners($now: DateTime!, $preview: Boolean!, $locale: String!) {
    promotionCollection(
      preview: $preview
      where: { OR: [{ endsAt_exists: false }, { endsAt_gte: $now }] }
      order: [precedence_ASC]
      locale: $locale
    ) {
      items {
        type
        title
        description
        image {
          url
          title
          width
          height
          description
        }
        startsAt
        endsAt
        slug
        precedence
      }
    }
  }
`;

/**
 * __useGetPromotionBannersQuery__
 *
 * To run a query within a React component, call `useGetPromotionBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionBannersQuery({
 *   variables: {
 *      now: // value for 'now'
 *      preview: // value for 'preview'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPromotionBannersQuery(
  baseOptions: Apollo.QueryHookOptions<GetPromotionBannersQuery, GetPromotionBannersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromotionBannersQuery, GetPromotionBannersQueryVariables>(
    GetPromotionBannersDocument,
    options,
  );
}
export function useGetPromotionBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPromotionBannersQuery,
    GetPromotionBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromotionBannersQuery, GetPromotionBannersQueryVariables>(
    GetPromotionBannersDocument,
    options,
  );
}
export type GetPromotionBannersQueryHookResult = ReturnType<typeof useGetPromotionBannersQuery>;
export type GetPromotionBannersLazyQueryHookResult = ReturnType<
  typeof useGetPromotionBannersLazyQuery
>;
export type GetPromotionBannersQueryResult = Apollo.QueryResult<
  GetPromotionBannersQuery,
  GetPromotionBannersQueryVariables
>;

import { Language } from 'constants/languages';

const mapping: Partial<Record<Language, string>> = {
  [Language.EN]: 'en-US',
  [Language.JA]: 'ja',
};

export function contentfulLocale(locale?: string): string {
  return mapping[locale as Language] || 'en-US';
}
